import React from "react";
import { Formik, Form } from "formik";

import TypeSubform from "./AttachmentForm/TypeSubform";
import Subforms from "./AttachmentForm/Subforms";
import { updateAttachment, completeAttachment } from "./api";
import humps from "humps";
import AutoSave from "./AttachmentForm/AutoSave";

const AttachmentForm = ({ model }) => {
  const handleError = (error, setFieldError) => {
    const errors = error.response.data;
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        setFieldError(key, errors[key]);
      }
    }
  };

  function redirectToTicket(attachmentId) {
    let url = "" + window.location;
    window.location.replace(
      url.replace(
        `/attachments/${attachmentId}/edit`,
        `#attachment_${attachmentId}`
      )
    );
  }

  // Получение только id региона выбранного населенного пункта с регионом
  function getRegionId ({ pharmacyRegion }) {
    const { id, regionId } = pharmacyRegion;

    return regionId || id;
  }

  function excludeEmptyMedicines(input) {
    const result = Object.assign({}, input);
    const isNotEmpty = (p) => p.medicineId != "";
    result.purchases = result.purchases.filter(isNotEmpty);
    result.prescriptionMedicines = result.prescriptionMedicines.filter(isNotEmpty);

    if (input.pharmacyRegion) result.pharmacyRegionId = getRegionId(input);

    return result;
  }

  const onComplete = (values, { setFieldError, setSubmitting }) => {
    setSubmitting(true);
    completeAttachment(excludeEmptyMedicines(values))
      .then(
        () => redirectToTicket(values.id),
        (error) => handleError(error, setFieldError)
      )
      .finally(() => setSubmitting(false));
  };

  // выключаем автоотправку формы, если не выбрано ЛС/ДВ
  const formIsUnready = (values) =>
    values.purchases.map((p) => p.medicineId).includes("") ||
    values.prescriptionMedicines.map((p) => p.medicineId).includes("");

  const onSubmit = (values, { setFieldError, setSubmitting }) => {
    setSubmitting(true);
    updateAttachment(excludeEmptyMedicines(values))
      .then(
        () => {},
        (error) => handleError(error, setFieldError)
      )
      .finally(() => setSubmitting(false));
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={humps.camelizeKeys(model)}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors, setFieldError, setSubmitting, values }) => (
        <React.Fragment>
          <Form>
            <AutoSave debounceMs={1000} />
            {Object.keys(errors).length > 0 && !isSubmitting ? (
              <div className="flashes">
                <div className="flash flash_error">Ошибка валидации</div>
              </div>
            ) : null}
            <TypeSubform />
            <Subforms />
          </Form>
          <div>
            <button
              className={
                isSubmitting || formIsUnready(values)
                  ? "button disabled"
                  : "button"
              }
              disabled={isSubmitting || formIsUnready(values)}
              onClick={(e) => {
                e.preventDefault();
                onComplete(values, { setFieldError, setSubmitting });
              }}
            >
              Проверить и завершить
            </button>
          </div>
        </React.Fragment>
      )}
    </Formik>
  );
};

export default AttachmentForm;
