import React, { useRef } from 'react';
import Viewer from 'react-viewer';

type Props = {
  imageUrls: string[],
  domId: string
}

const ImageViewer: React.FC<Props> = ({imageUrls, domId}) => {
  const container = useRef(document.getElementById(domId));
  const images = imageUrls.map(src => ({src: src, downloadUrl: src}))
  return (
      <Viewer
        container={container.current}
        visible={true}
        noClose={true}
        noImgDetails={true}
        disableKeyboardSupport={true}
        changeable={false}
        downloadable={true}
        downloadInNewWindow={true}
        zoomSpeed={0.3}
        minScale={1}
        noNavbar={images.length < 2}
        scalable={false}
        images={images}
      />
  );
}

export default ImageViewer
