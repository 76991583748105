import React from "react";
import { useField, useFormikContext } from "formik";
import Select from "react-select";

const AASelectInput = ({
  label,
  hint,
  withEmptyOption = true,
  boolean = false,
  options = [],
  isFieldValueObject,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  const onChange = selection => {
    const value = isFieldValueObject ? selection : selection.value;
  
    setFieldValue(props.name, value);
  }

  if (boolean) {
    options = [
      { value: true, label: "Да" },
      { value: false, label: "Нет" },
    ];
  }

  // Поиск значения в опциях
  const findValue = () => options.find(option => option.value === field.value);

  const getValue = () => isFieldValueObject ? field.value : findValue();

  return (
    <li>
      <label htmlFor={props.id || props.name} className="custom_label">{label}</label>
      <Select
        {...field}
        {...props}
        options={options}
        value={getValue()}
        onBlur={field.onBlur}
        onChange={onChange}
      />
      {meta.error ? (
        <p className="custom_error">{meta.error}</p>
      ) : null}
      {hint ? <p className="custom_hint">{hint}</p> : null}
    </li>
  );
};

export default AASelectInput;
